import $ from 'jquery';
import iziModal from 'izimodal';
$.fn.iziModal = iziModal;

import picturefill from "picturefill";
picturefill();


// 実行
$(function () {
  let smoothScrollParam,
      headerFixedParam;

  // modal
  modal();

  // スムーススクロール
  smoothScrollParam = {
    time: 1000,
    offsetY: $('.header').outerHeight()
  }

  new SmoothScroll(smoothScrollParam);


  // header sticky menu
  headerFixedParam = {
    onDistance: $('.mainVisual').length > 0 ? $('.mainVisual').outerHeight() : 400
  }

  new HeaderPagetopFixed(headerFixedParam);
});


// modal
const modal = ()=> {
  const noScroll = new NoScroll(),
        $body = $('body');

  $(document).on('click', '.jsModalTrigger', function (event) {
    event.preventDefault();
    let modalContent = String($(this).attr('href'));
    let $elem = $(modalContent);

    $elem.iziModal({
      width: 900,
      padding: '80px 110px',
      radius: 0,
      transitionIn: 'fadeInUp',
      transitionOut: 'fadeOutDown',
      onOpening: function () {
        noScroll.on();
      },
      onClosing: function () {
        noScroll.off();
      }
    });

    $elem.iziModal('open');

  });
}

// noScroll
class NoScroll {
  constructor() {
    this.w = $(window);
    this.body = $('body');
  }

  on() {
    this.body.addClass('isModalOpen');

    window.addEventListener('touchmove', this.moveFunction, {
      passive: false
    });
  }

  off() {
    this.body.removeClass('isModalOpen');
    window.removeEventListener('touchmove', this.moveFunction, {
      passive: false
    });
  }

  moveFunction(e) {
    e.preventDefault();
  }
}


// スムーズスクロール
class SmoothScroll {
  constructor(param) {
    this.$body = $('body');
    this.time = param.time;
    this.offsetY = param.offsetY; // ヘッダー等の固定領域がある場合はその値
    this.hash = location.hash;

    this.init();
  }

  init() {
    const margin = this.offsetY,
          time = this.time,
          hash = this.hash;

    this.$body.on('click', '[data-scroll]', function () {
      let href = $(this).attr('href'),
          $target = $(href),
          pos = ($target[0] && $target !== '#pagetop') ? $target.offset().top - margin : 0;

      $('html,body').animate({
        scrollTop: pos
      }, {
          duration: time,
          easing: 'swing'
        });

      $(this).blur();

      return false;
    });

    $(window).on('load', ()=>{
      if (hash.length < 1) {
        return
      }

      let href = hash,
        $target = $(href),
        pos = ($target[0] && $target !== '#pagetop') ? $target.offset().top - margin : 0;

      $('html,body').animate({
        scrollTop: pos
      }, {
        duration: time,
        easing: 'swing'
      });

      $(this).blur();

      return false;
    });
  }
}

// headerFixed
class HeaderPagetopFixed {
  constructor(param) {
    this.$w = $(window);
    this.onDistance = param.onDistance;
    this.scroll;
    this.timeoutId;
    this.$header = $('.header');
    this.$pageTop = $('.pagetop');

    this.sroll();
  }

  header(scroll) {
    if (scroll > this.onDistance) {
      this.$header.addClass('isHeaderSticky');
    } else {
      this.$header.removeClass('isHeaderSticky');
    }
  }

  pageTop(scroll) {
    if (scroll > this.onDistance) {
      this.$pageTop.addClass('isActive');
    } else {
      this.$pageTop.removeClass('isActive');
    }
  }

  sroll() {
    this.$w.on('scroll', () => {
      this.header(this.$w.scrollTop());
      this.pageTop(this.$w.scrollTop());
    });
  }
}


